// 
import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

import en from './locales/en.json'

export const i18n = new VueI18n({
  locale: document.getElementsByTagName('html')[0].getAttribute('lang'),
  fallbackLocale: 'en',
  messages: {
    en
  }
})