<template>
	<select name="band_id" class="mb-6" v-model="band_id">
        <option v-for="(band, index) in bands" :key="index" :value="band.band_id">{{ band.band_name }}</option>
	</select>
</template>

<script>

import { eventBus } from '../store/store.js';

export default {
    props: {
        bands: {
            type: Array,
            default: null
        },
        url: {
            type: String,
            default: ''
        },
        selected: {
            type: [String],
            default: null
        }
    },
	data() {
		return {
            band_id: ''
		}
	}
	, created() {

            if (!_.isEmpty(this.selected)) this.band_id = this.selected;
	}
	, watch: {
        
        band_id: function()
        {
            if(_.isEmpty(this.band_id)) window.location.href = this.url+'?band='+this.band_id;
        }
     
	}
}
</script>

