<template>
    <div @click="onSelect" class="citem">
        <div class="citem__label" :class="{ 'citem__label--active' : active }">
            <span>
                <i class="fas fa-circle mr-2" v-if="!active"></i>
                <i class="fas fa-check-circle mr-2" v-if="active"></i>
            </span>
            <slot></slot>
            <input type="checkbox" class="citem__input" name="competencies[]" v-model="active" :value="value" />
        </div>
	</div>
</template>

<script>

import { eventBus } from '../store/store.js';
import { mapGetters } from 'vuex';

export default {
    props: {
        value: Number,
        competency: Object,
        checked: {
            type: Boolean,
            default: false
        }
    },
	data() {
		return {
            active: false
		}
	},
    created() {

        this.$nextTick(function () {

             if (this.competency.competency_mandatory) {
                 this.active = true;
                 return;
             }

            if (this.hasOldData) {
                this.active = _.indexOf(this.old, this.value.toString()) > -1;
                if (this.active) this.$emit('activate');
            } else {
                this.active = this.checked;
            }
        });
 
        eventBus.$on('bandchange', (v) => {
            if (!this.competency.competency_mandatory) this.active = false;
		});
    },
    methods: {
        onSelect: function() {
            if (this.competency.competency_mandatory) return;
            this.active = !this.active
        }
    },
    watch: {
        checked: function() {
            this.active = this.checked;
        }
    },
    computed: {

        hasOldData: function() {
            return this.$store.getters.getData.hasOwnProperty('old');
        },

        old: function() {
            return this.$store.getters.getData['old'];
        },

        ...mapGetters([
            'getData'
        ])
    }
}
</script>

