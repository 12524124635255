let getters = {

    navActive: state => {
        return state.nav
    },

    getData: state => {
        return state.data;
    }
    
}

export default getters