var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _c(
      "div",
      { staticClass: "accordion__header", on: { click: _vm.toggleContent } },
      [
        _c("i", {
          staticClass: "far mr-2",
          class: { "fa-chevron-up": _vm.active, "fa-chevron-down": !_vm.active }
        }),
        _vm._v(_vm._s(_vm.group.competency_group_text.competency_group_title))
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "accordion__content",
        style: { "max-height": _vm.contentHeight }
      },
      [
        _c(
          "div",
          { ref: "group_content", staticClass: "accordion__grid" },
          _vm._l(_vm.group.competencies, function(competency) {
            return _c(
              "competency-item",
              {
                key: competency.competency_id,
                attrs: {
                  competency: competency,
                  value: competency.competency_id,
                  checked: _vm.isChecked(competency)
                },
                on: { activate: _vm.onActivate }
              },
              [
                competency.competency_mandatory
                  ? _c("span", { staticClass: "citem--required" }, [
                      _vm._v("*")
                    ])
                  : _vm._e(),
                _vm._v(_vm._s(competency.competency_text.competency_title))
              ]
            )
          }),
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }