require('./bootstrap');

// require('alpinejs');

var hasApp = document.getElementById('app');

import Vue from 'vue'
import { i18n } from './i18n/index.js'
import store from './store/store';

if (hasApp) {

	const files = require.context('./', true, /\.vue$/i)
	files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

	// Init directive for passing intial select states
	Vue.directive('init', {
		bind: function(el, binding, vnode) {
			vnode.context[binding.arg] = binding.value;
		}
	});

	const app = new Vue({
		el: '#app',
		store,
		i18n,
		data() {
			return {
			
			}
		}
	});

}