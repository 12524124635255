var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "toggle-switch" }, [
    _c("label", { staticClass: "switch" }, [
      _c("input", {
        attrs: { type: "checkbox" },
        domProps: { checked: _vm.active },
        on: { click: _vm.onClick }
      }),
      _vm._v(" "),
      _c("span", { staticClass: "slider round" })
    ]),
    _vm._v(" "),
    _c("span", { staticClass: "ml-2" }, [
      _vm._v(_vm._s(this.active ? "Active" : "Deactivated"))
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }