<template>
    <div @click="onSelect" class="citem">   
        <div class="citem__label citem__label--nogrid" :class="{ 'citem__label--active' : active }">
            <span>
                <i class="fas fa-circle mr-2" v-if="!active"></i>
                <i class="fas fa-check-circle mr-2" v-if="active"></i>
            </span>
            <slot></slot>
		    <input type="checkbox" name="questions[]" class="citem__input" v-model="active" :value="value" />
        </div>
	</div>
</template>

<script>

import { eventBus } from '../store/store.js';
import { mapGetters } from 'vuex';

export default {
    props: {
        value: Number,
        checked: false
    },
	data() {
		return {
            active: false
		}
	},
    created() {

        this.$nextTick(function () {
            if (this.hasOldData) {
                this.active = _.indexOf(this.old, this.value.toString()) > -1;
            } else {
                this.active = this.checked;
            }
        });

    },
    methods: {
        onSelect: function() {
            this.active = !this.active
        }
    },
    watch: {
        checked: function() {
            this.active = this.checked;
        }
    },
    computed: {

        hasOldData: function() {
            return this.$store.getters.getData.hasOwnProperty('old');
        },

        old: function() {
            return this.$store.getters.getData['old'];
        },

        ...mapGetters([
            'getData'
        ])
    }
}
</script>

