var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    { attrs: { name: "fade" }, on: { "after-leave": _vm.onClose } },
    [
      _vm.open
        ? _c("div", { staticClass: "modal-mask" }, [
            _c("div", { staticClass: "modal-wrapper" }, [
              _c("div", { staticClass: "modal-container" }, [
                _c("div", { staticClass: "modal-header" }, [
                  _c("div", { staticClass: "modal-header--title" }, [
                    _c("i", { staticClass: "fas fa-exclamation-circle mr-2" }),
                    _vm._v(_vm._s(_vm.title) + "\n\t\t\t\t\t\t")
                  ]),
                  _vm._v(" "),
                  _c("a", { on: { click: _vm.onCancel } }, [
                    _c("i", { staticClass: "far fa-times" })
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "modal-body" }, [
                  _c("p", {}, [_vm._t("default")], 2)
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "modal-footer" }, [
                  _c(
                    "a",
                    {
                      staticClass: "btn btn--primary",
                      on: { click: _vm.onConfirm }
                    },
                    [_vm._v(_vm._s(_vm.okLbl))]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "btn btn--hollow",
                      on: { click: _vm.onCancel }
                    },
                    [_vm._v(_vm._s(_vm.cancelLbl))]
                  )
                ])
              ])
            ])
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }