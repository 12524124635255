var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "tabs" },
      _vm._l(_vm.tabs, function(tab) {
        return _c(
          "a",
          {
            key: tab.id,
            staticClass: "btn",
            class: {
              "btn--primary": tab.isActive,
              "btn--hollow-alt": !tab.isActive
            },
            on: {
              click: function($event) {
                return _vm.selectTab(tab)
              }
            }
          },
          [_vm._v(_vm._s(tab.name))]
        )
      }),
      0
    ),
    _vm._v(" "),
    _c("div", { staticClass: "tab-content" }, [_vm._t("default")], 2)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }