import { render, staticRenderFns } from "./SuccessMessage.vue?vue&type=template&id=329767af&"
import script from "./SuccessMessage.vue?vue&type=script&lang=js&"
export * from "./SuccessMessage.vue?vue&type=script&lang=js&"
import style0 from "./SuccessMessage.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/nickmorley/Downloads/AMS-HANDOVER/Royal-London-Interview-Toolkit/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('329767af')) {
      api.createRecord('329767af', component.options)
    } else {
      api.reload('329767af', component.options)
    }
    module.hot.accept("./SuccessMessage.vue?vue&type=template&id=329767af&", function () {
      api.rerender('329767af', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/SuccessMessage.vue"
export default component.exports