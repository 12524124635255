<template>
	<div v-show="hasResults" class="mt-2">
		<label class="required mb-2">Available Capabilities</label>
		<div class="" v-for="(group, index) in groups" :key="index">
			<competency-group :group="group" :selected="selected"></competency-group> 
		</div>
	</div>
</template>

<script>

import { eventBus } from '../store/store.js';

	export default {
		data() {
			return {
				groups: [],
				band_id: '',
				selected: [],
			}
		}
		, props: {
			old: {
				type: Array,
				default: null
			},
			template: {
				type: Number,
				default: null
			}
		}
		, mounted() {

			if (this.old) this.$store.dispatch('setData', {key:'old', value:this.old});

		}
		, created() {

			eventBus.$on('bandchange', (v) => {
				this.band_id = v;
				
				if(typeof(v) == 'number') {
					this.getCompetencyGroups();
					return;
				}

				this.groups = [];

			});

		}
		, methods: {
			
			getCompetencyGroups: function() {

				axios.get('/api/competencies/groups', {
					params: {
						band: this.band_id
					}
				}).then((response) => {
					
					this.groups = response.data;

					if (this.template) {
						this.getSelectedCompetencies();
					}
				});
			}
			, getSelectedCompetencies() {
				axios.get('/api/templates/'+this.template+'/competencies', {

				}).then((response) => {
					this.selected = response.data;
				});
			},
		}
		, computed: {

			hasResults: function() {

				return this.groups.length;

			}

		}
	}
</script>