<template>
  <div class="technical-questions">
    <div class="technical-questions__container">
      <div
        class="technical-questions__question"
        v-for="question in technical_questions"
        :key="question.technical_question_id"
      >
        <technical-question
          :question="question"
          :max_characters="max_characters"
          @delete="onDelete"></technical-question>
      </div>
    </div>

    <div class="technical-questions__form">
      <form>
      <div class="technical-questions__form-group">
        <label class="required" for="question">{{ $t('question_label') }}</label>
        <textarea id="question" name="question" v-model="question"></textarea>
        <p
          class="technical-questions__remaining"
          :class="{ 'technical-questions__remaining--error': errorQuestion }"
        >{{ $t('characters_left') }}: {{ charactersRemainingQuestion }}</p>
      </div>
      <div class="technical-questions__form-group">
        <label class="required" for="strong_answer">{{ $t('positive_indicators') }}</label>
        <textarea id="strong_answer" name="strong_answer" v-model="strong_answer"></textarea>
        <p
          class="technical-questions__remaining"
          :class="{ 'technical-questions__remaining--error': errorStrongAnswer }"
        >{{ $t('characters_left') }}: {{ charactersRemainingStrongAnswer }}</p>
      </div>
      <div class="technical-questions__form-group">
        <label class="required" for="weak_answer">{{ $t('negative_indicators') }}</label>
        <textarea id="weak_answer" name="weak_answer" v-model="weak_answer"></textarea>
        <p
          class="technical-questions__remaining"
          :class="{ 'technical-questions__remaining--error': errorWeakAnswer }"
        >Characters left: {{ charactersRemainingWeakAnswer }}</p>
      </div>
      
      <div class="technical-questions__form-group">
        <a class="btn btn--hollow mt-4" @click="resetForm">{{ $t('buttons.lbl_reset') }}</a>
        <transition name="fade">
          <a class="btn btn--primary mt-4" v-if="canPost" @click="addQuestion">{{ $t('buttons.lbl_save_question') }}</a>
        </transition>
      </div>
      </form>
       <modal-dialog title="Delete Question?" :open="isOpen" @cancel="onCancel" @close="onClose" @confirm="onConfirm" okLbl="Yes" cancelLbl="No">Are you sure you want to delete this question?</modal-dialog>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    template: Number
  },
  data() {
    return {
      technical_questions: [],
      question: "",
      weak_answer: "",
      strong_answer: "",
      max_characters: 1000,
      isOpen: false,
      row: null
    };
  },
  computed: {
    charactersRemainingQuestion() {
      return this.max_characters - this.question.length;
    },
    charactersRemainingWeakAnswer() {
      return this.max_characters - this.weak_answer.length;
    },
    charactersRemainingStrongAnswer() {
      return this.max_characters - this.strong_answer.length;
    },
    canPost() {
      return !_.isEmpty(this.question) && !_.isEmpty(this.weak_answer) && !_.isEmpty(this.strong_answer);
    },
    errorQuestion() {
      return this.charactersRemainingQuestion < 0;
    },
    errorWeakAnswer() {
      return this.charactersRemainingWeakAnswer < 0;
    },
    errorStrongAnswer() {
      return this.charactersRemainingStrongAnswer < 0;
    }
  },
  mounted() {
    this.getTechnicalQuestions();
  },
  methods: {

    resetForm: function() {
      this.question = '';
      this.weak_answer = '';
      this.strong_answer = '';
    },

    onCancel: function() {
      this.isOpen = false;
      this.row = null;
    },

    onConfirm: function() {
      
     axios.post('/api/templates/'+this.row.template_id+'/custom/'+this.row.technical_question_id+'/delete', {
				technical_question_id: this.row.technical_question_id
			}).then((response) => {
				this.removeQuestion(this.row);
        this.isOpen = false;
			});
    },

    onClose: function() {
      this.isOpen = false;
      this.row = null;
    },

    getTechnicalQuestions() {
      axios
        .get('/api/templates/'+this.template+'/custom')
        .then(response => {
          if (response.data) {
            this.technical_questions = response.data;
          }
        });
    },
    addQuestion() {
      axios
        .post('/api/templates/'+this.template+'/custom/create', {
          question: this.question,
          weak_answer: this.weak_answer,
          strong_answer: this.strong_answer
        })
        .then(response => {
          if (response.data) {
            this.technical_questions.push(response.data);
            this.question = "";
            this.weak_answer = "";
            this.strong_answer = "";
          }
        });
    },

    onDelete(q) {
      this.row = q;
      this.isOpen = true;
    },

    removeQuestion(question) {
      //console.log(this.question)
      let index = this.technical_questions.indexOf(question);
      this.technical_questions.splice(index, 1);
      this.row = null;
    }
  }
};
</script>