<template>
    
    <div>
        <transition name="fade">
            <div class="alert alert--error" v-if="errors.length">
                <ul class="alert__list">
                    <li v-bind:key="index" v-for="(error, index) of errors">{{ error }}</li>
                </ul>
            </div>
         </transition>

        <form>
            <div class="search">
                <div class="search__filters">
                    <input @input="searchUsers" v-model="search" type="text" placeholder="Start typing to search users..." />
                    <select v-model="status" @change="fetchUsers">
                        <option value="">Select status...</option>
                        <option v-for="stat in statuses" :key="stat.id" :value="stat.id">{{ stat.name }}</option>
                    </select>
                </div>
      
                <div class="search__controls mt-4">
                    
                    <a @click="onReset" class="btn btn--hollow"><i class="fas fa-undo mr-3"></i>Reset</a>
                    <transition name="fade">
                    <a :href="getExportURI" class="btn btn--primary" v-if="hasResults"><i class="fas fa-file-download mr-3"></i>Export Results</a>  
                    </transition>   
                </div>
            </div>
        </form>

        <div class="flex flex-col mt-6">
            <div class="overflow-hidden">
                <div class="table">
                    <table class="">
                        <thead class="">
                            <tr>
                                <th>
                                    Name
                                </th>
                                <th>
                                    Email
                                </th>   
                                <th>
                                    Country
                                </th>
                                <th>
                                    Business Area
                                </th>
                                <th style="text-align:center;">
                                    Templates
                                </th>
                                <th style="text-align:center;">
                                    Rating
                                </th>
                                <th style="text-align:center;">
                                    Role
                                </th>
                                <th style="text-align:center;">
                                    Status
                                </th>
                                <th style="text-align:center;">
                                    Options
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="noResults">
                                <td colspan="8" style="text-align:center;">No Results</td>
                            </tr>

                            <tr v-for="result in results" :key="result.id">
                                <td><strong>{{ result.full_name }}</strong></td>
                                <td>{{ result.email }}</td>
                                <td>{{ result.country }}</td>
                                <td>{{ result.business_area }}</td>
                                <td style="text-align:center;">{{ result.templates }}</td>
                                <td style="text-align:center;">{{ result.rating }}</td>
                                <td style="text-align:center;">{{ result.role }}</td>
                                <td style="text-align:center;">
                                    <span v-if="result.status == 'Pending'" class="status status--pending"><strong>{{ result.status }}</strong></span>
                                    <span v-if="result.status == 'Active'" class="status status--active"><strong>{{ result.status }}</strong></span>
                                    <span v-if="result.status == 'Deactivated'" class="status status--deactivated"><strong>{{ result.status }}</strong></span>
                                </td>
                                <td style="text-align:center;" align="center">
                                    <a :href="'/admin/users/'+result.id" class=""><i class="fas fa-eye"></i></a>
                                    <a :href="'/admin/users/'+result.id+'/edit'" class="pl-2" v-if="result.status != 'Pending'"><i class="fad fa-pencil-alt"></i></a>
                                    <a @click="onDelete(result)" class="pl-2"><i class="fas fa-trash-alt"></i></a>
                                </td>   
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <paginated-results :current="meta.current_page" :last="meta.last_page" :total="meta.total" :prev="links.prev" :next="links.next" :path="path" @paginate="onPaginate"></paginated-results>
        <modal-dialog title="Delete User?" :open="isOpen" @cancel="onCancel" @close="onClose" @confirm="onConfirm" okLbl="Yes" cancelLbl="No">Are you sure you want to delete the user <span class="font-bold font-gray-800">{{ username }}</span>?</modal-dialog>
    </div>

</template>

<script>

export default {
 
	props: {
		users: Object,
        statuses: Array
	},
	data() {
		return {
            url: '/api/users',
            search: '',
            results: [],
            errors: [],
            meta: [],
            links: [],
            page: 1,
            path: '',
            status: '',
            isOpen: false,
            username: '',
            userid: null
		}
	}
	, created() {
        this.fetchUsers();
	}
	, methods: {

        searchUsers: _.debounce(function(ev) {

            this.fetchUsers();

        }, 500),

        fetchUsers: function() {
            

            let uriparams = {page: this.page};

            if (this.status !== '') uriparams.status = this.status;
            if (!_.isEmpty(this.search)) uriparams.search = encodeURI(this.search);

            axios.get(this.url, {
                params: uriparams
            }
            ).then(response => {
                this.results = response.data.data
                this.meta = response.data.meta
                this.links = response.data.links
                this.path = this.url+'?search='+encodeURI(this.search)
            });
        },

        onDelete: function(d) {
            this.username = d.full_name;
            this.userid = d.id;
            this.isOpen = true;
        },

        onCancel: function() {
            this.isOpen = false;
        },

        onClose: function() {
            this.username = '';
            this.userid = null;
        },

        onConfirm: function() {

            axios.delete('/api/users/'+this.userid+'/delete').then(response => {

                if (response.data.errors)
                {
                    this.errors = response.data.errors;
                    var vm = this;

                    this.$nextTick(function () {

                        setTimeout(function() {
                            
                            vm.errors = [];

                        }, 3000);

                    });

                } else {
                    this.fetchUsers();
                }
      
                
                this.isOpen = false;
            })
            
        },
        
        onReset: function() {
            this.username = '';
            this.userid = null;
            this.search = '';
            this.status = '';
            this.fetchUsers();
        },

        onPaginate: function(p) {
            this.page = p;
            this.fetchUsers();
        },

        onFilter: function(e) {
            this.fetchUsers();
        }
        
	},
    computed: {
        noResults: function() {
            return !this.results.length;
        },
        hasResults: function() {
            return this.results.length;
        },
        getSearchURI: function() {
            return this.url;//+'?search='+encodeURI(this.search)+'&pending='+this.pending+'&page='+this.page;
        },
        getExportURI: function() {
            return '/admin/users/export?search='+encodeURI(this.search)+'&status='+this.status;
        }

    }
}
</script>
<style>
	.fade-enter-active, .fade-leave-active {
		transition: opacity 0.3s;
	}
	.fade-enter, .fade-leave-to {
		opacity: 0;
	}
</style>