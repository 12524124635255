var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "technical-questions" }, [
    _c(
      "div",
      { staticClass: "technical-questions__container" },
      _vm._l(_vm.technical_questions, function(question) {
        return _c(
          "div",
          {
            key: question.technical_question_id,
            staticClass: "technical-questions__question"
          },
          [
            _c("technical-question", {
              attrs: { question: question, max_characters: _vm.max_characters },
              on: { delete: _vm.onDelete }
            })
          ],
          1
        )
      }),
      0
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "technical-questions__form" },
      [
        _c("form", [
          _c("div", { staticClass: "technical-questions__form-group" }, [
            _c(
              "label",
              { staticClass: "required", attrs: { for: "question" } },
              [_vm._v(_vm._s(_vm.$t("question_label")))]
            ),
            _vm._v(" "),
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.question,
                  expression: "question"
                }
              ],
              attrs: { id: "question", name: "question" },
              domProps: { value: _vm.question },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.question = $event.target.value
                }
              }
            }),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "technical-questions__remaining",
                class: {
                  "technical-questions__remaining--error": _vm.errorQuestion
                }
              },
              [
                _vm._v(
                  _vm._s(_vm.$t("characters_left")) +
                    ": " +
                    _vm._s(_vm.charactersRemainingQuestion)
                )
              ]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "technical-questions__form-group" }, [
            _c(
              "label",
              { staticClass: "required", attrs: { for: "strong_answer" } },
              [_vm._v(_vm._s(_vm.$t("positive_indicators")))]
            ),
            _vm._v(" "),
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.strong_answer,
                  expression: "strong_answer"
                }
              ],
              attrs: { id: "strong_answer", name: "strong_answer" },
              domProps: { value: _vm.strong_answer },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.strong_answer = $event.target.value
                }
              }
            }),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "technical-questions__remaining",
                class: {
                  "technical-questions__remaining--error": _vm.errorStrongAnswer
                }
              },
              [
                _vm._v(
                  _vm._s(_vm.$t("characters_left")) +
                    ": " +
                    _vm._s(_vm.charactersRemainingStrongAnswer)
                )
              ]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "technical-questions__form-group" }, [
            _c(
              "label",
              { staticClass: "required", attrs: { for: "weak_answer" } },
              [_vm._v(_vm._s(_vm.$t("negative_indicators")))]
            ),
            _vm._v(" "),
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.weak_answer,
                  expression: "weak_answer"
                }
              ],
              attrs: { id: "weak_answer", name: "weak_answer" },
              domProps: { value: _vm.weak_answer },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.weak_answer = $event.target.value
                }
              }
            }),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "technical-questions__remaining",
                class: {
                  "technical-questions__remaining--error": _vm.errorWeakAnswer
                }
              },
              [
                _vm._v(
                  "Characters left: " +
                    _vm._s(_vm.charactersRemainingWeakAnswer)
                )
              ]
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "technical-questions__form-group" },
            [
              _c(
                "a",
                {
                  staticClass: "btn btn--hollow mt-4",
                  on: { click: _vm.resetForm }
                },
                [_vm._v(_vm._s(_vm.$t("buttons.lbl_reset")))]
              ),
              _vm._v(" "),
              _c("transition", { attrs: { name: "fade" } }, [
                _vm.canPost
                  ? _c(
                      "a",
                      {
                        staticClass: "btn btn--primary mt-4",
                        on: { click: _vm.addQuestion }
                      },
                      [_vm._v(_vm._s(_vm.$t("buttons.lbl_save_question")))]
                    )
                  : _vm._e()
              ])
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c(
          "modal-dialog",
          {
            attrs: {
              title: "Delete Question?",
              open: _vm.isOpen,
              okLbl: "Yes",
              cancelLbl: "No"
            },
            on: {
              cancel: _vm.onCancel,
              close: _vm.onClose,
              confirm: _vm.onConfirm
            }
          },
          [_vm._v("Are you sure you want to delete this question?")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }