<template>
    <nav class="pagination mt-4" v-if="total">
        <div class="pagination__left">
            <a @click="goPrev" :class="{ 'pagination--disabled' : current <= 1 }"><i class="far fa-long-arrow-left mr-2"></i>Previous</a>
        </div>
        <div class="pagination__center" >
            <a @click="setPage(value)" :class="{ 'pagination--active' : value == current }" v-for="(value, index) in last" :key="index">{{ value }}</a>
        </div>
        <div class="pagination__right">
            <a @click="goNext" :class="{ 'pagination--disabled' : current >= total }">Next<i class="far fa-long-arrow-right ml-2"></i></a>
        </div>
    </nav>
</template>

<script>
export default {
	props: {
        path: String,
        total: Number,
        current: Number,
        last: Number,
        prev: String,
        next: String,
	},
	data() {
		return {

		}
	}
	, created() {
   
	}
	, methods: {
        setPage: function(v) {
            if (v != this.current) this.$emit('paginate', v);
        },
        goPrev: function() {
            var prevPage = this.current == 1 ? 1 : this.current-1;
            this.$emit('paginate', prevPage);
        },
        goNext: function() {
            var nextPage = this.current == this.last ? this.last : this.current+1;
            this.$emit('paginate', nextPage);
        }
	}
}
</script>