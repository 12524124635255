<template>
    <div>

		<transition name="fade">
			<div v-if="isSuccess" class="alert alert--success" v-html="success"></div>
		</transition>

		<form>

			<div class="mb-4">
				<label for="data_type" class="required">Data Type:</label>
				<select name="data_type" v-model="data_type">
					<option value="template_data">Template Data</option>
					<option value="business_area">Business Areas</option>
				</select>
			</div>

			<div class="mb-4">
				<label for="language_id" class="required">Language:</label>
				<select name="language_id" v-model="language_id">
					<option v-for="(lang, index) in langs" :key="index" :value="lang.language_id">{{ lang.language_name }}</option>
				</select>
			</div>

			<input type="file" ref="filepicker" name="file" style="display:none;" @change="onChange" />
			
			<div v-if="!canUpload" class="p-4">
				<p class="mb-2" style="font-size:.875em;">Select File: <span class="text-sm">(csv, xls - max. file size 10MB)</span></p>
				<a class="btn btn--primary" @click="showFilePicker"><i class="far fa-paperclip mr-2"></i>Choose File</a>
			</div>

			<div class="p-4" v-if="canUpload">
				<div class="alert alert--success" style="display:flex; justify-content:space-between;">
					<p>{{ fileName }}</p>
					<a v-if="canUpload" @click="clearFiles" class="cursor-pointer"><i class="fas fa-times-circle"></i></a>
				</div>
				<input type="file" ref="file" name="file" style="display:none;" @change="onChange" />
				<a @click="onSubmit" class="btn btn--primary"><i :class="uploadClass" class="mr-2"></i>Upload File</a>
			</div>

		</form>

	</div>
	
</template>

<script>

export default {

    props: {
		langs: Array
    },

	data () {

    	return {
			files: null,
			errors: [],
			success: '',
			selected: null,
			uploading: false,
			percentage: 0,
			selectedType: '',
			language_id: 1,
			data_type: 'template_data'
    	}
	},

	methods: {

		onSubmit: function()
		{

			this.clearSuccess();
			this.clearErrors();
			this.uploading = true;
			let formData = new FormData();
			

			formData.append('files', this.files);
			formData.append('data_type', this.data_type);
			formData.append('language_id', this.language_id);

			axios.post('/admin/data/import', formData, { onUploadProgress: function( progressEvent ) {
        this.percentage = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ));
        this.$refs.progress = this.percentage
      }.bind(this) })
			.then(response => {
				if (response.data.errors) {
					this.errors = response.data.errors;
					this.uploading = false;
					this.percentage = 0;
				}
				if (response.data.success) {
					let vm = this;
					this.success = response.data.success;
					this.clearFiles();
					this.uploading = false;
					this.percentage = 0;
					// setTimeout(function() {
					//     //vm.clearSuccess();
					// }, 2000);
				}
			})
			 .catch((errors) => {
                this.errors = errors;
            })
		},

		showFilePicker: function()
		{
			this.clearSuccess();
			this.clearErrors();
			this.$refs.filepicker.click();
		},

		onChange: function(event) 
		{
			this.files = event.target.files[0];
			this.uploading = false;
			this.percentage = 0;
		},

		onTypeChange: function(event) 
		{
			this.clearSuccess();
			this.clearErrors();
			this.type = event;
		},

		clearFiles: function() {
           this.$refs.filepicker.value = '';
		   this.files = null;
		   this.selectedType = '';
		   this.percentage = 0;
		   this.clearErrors();
		   
        },

		clearErrors: function() {
            this.errors = [];
        },

		clearSuccess: function() {
            this.success = '';
			this.uploading = false;
			this.percentage = 0;
        },

	},

	computed: {

		fileName: function() 
		{
			return this.files ? this.files.name : '';
		},

		canUpload: function() 
		{
			return this.files;
		},

		isSuccess: function() 
		{
			return !_.isEmpty(this.success);
		},

		uploadClass: function()
		{
			return this.uploading ? 'fas fa-spinner fa-pulse mr--xs' : 'fas fa-cloud-upload-alt mr--xs';
		},

		progressBar () {
			return { width: `${this.percentage}%` };
      	}

	}
}

</script>
<style>
	.fade-enter-active, .fade-leave-active {
		transition: opacity 0.3s;
	}
	.fade-enter, .fade-leave-to {
		opacity: 0;
	}
</style>