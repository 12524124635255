var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "citem", on: { click: _vm.onSelect } }, [
    _c(
      "div",
      {
        staticClass: "citem__label citem__label--nogrid",
        class: { "citem__label--active": _vm.active }
      },
      [
        _c("span", [
          !_vm.active
            ? _c("i", { staticClass: "fas fa-circle mr-2" })
            : _vm._e(),
          _vm._v(" "),
          _vm.active
            ? _c("i", { staticClass: "fas fa-check-circle mr-2" })
            : _vm._e()
        ]),
        _vm._v(" "),
        _vm._t("default"),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.active,
              expression: "active"
            }
          ],
          staticClass: "citem__input",
          attrs: { type: "checkbox", name: "questions[]" },
          domProps: {
            value: _vm.value,
            checked: Array.isArray(_vm.active)
              ? _vm._i(_vm.active, _vm.value) > -1
              : _vm.active
          },
          on: {
            change: function($event) {
              var $$a = _vm.active,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = _vm.value,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.active = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.active = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.active = $$c
              }
            }
          }
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }