<template>
	<transition name="fade">
    	<div class="mb-4 text-sm bg-green-100 text-green-800 py-3 px-4 rounded-md" v-if="visible"><slot></slot></div>
	</transition>
</template>

<script>
  	export default {
		data() {
			return {
                visible: true
			}
    	},
		mounted() {

			var vm = this;

  			this.$nextTick(function () {

				setTimeout(function() {
					
					vm.visible = false;

				}, 3000);

  			});
		}
  	}
</script>
<style>
	.fade-enter-active, .fade-leave-active {
		transition: opacity 0.3s;
	}
	.fade-enter, .fade-leave-to {
		opacity: 0;
	}
</style>