var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.total
    ? _c("nav", { staticClass: "pagination mt-4" }, [
        _c("div", { staticClass: "pagination__left" }, [
          _c(
            "a",
            {
              class: { "pagination--disabled": _vm.current <= 1 },
              on: { click: _vm.goPrev }
            },
            [
              _c("i", { staticClass: "far fa-long-arrow-left mr-2" }),
              _vm._v("Previous")
            ]
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "pagination__center" },
          _vm._l(_vm.last, function(value, index) {
            return _c(
              "a",
              {
                key: index,
                class: { "pagination--active": value == _vm.current },
                on: {
                  click: function($event) {
                    return _vm.setPage(value)
                  }
                }
              },
              [_vm._v(_vm._s(value))]
            )
          }),
          0
        ),
        _vm._v(" "),
        _c("div", { staticClass: "pagination__right" }, [
          _c(
            "a",
            {
              class: { "pagination--disabled": _vm.current >= _vm.total },
              on: { click: _vm.goNext }
            },
            [
              _vm._v("Next"),
              _c("i", { staticClass: "far fa-long-arrow-right ml-2" })
            ]
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }