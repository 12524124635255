var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mt-2 templates__shared" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "mt-2 templates__shared__controls" }, [
      _c("label", {}, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.shared,
              expression: "shared"
            }
          ],
          attrs: { type: "radio", name: "shared", value: "0" },
          domProps: { checked: _vm._q(_vm.shared, "0") },
          on: {
            change: function($event) {
              _vm.shared = "0"
            }
          }
        }),
        _vm._v(" "),
        _c("i", {
          staticClass: "far",
          class: {
            "fa-dot-circle": _vm.shared == 0,
            "fa-circle": _vm.shared == 1
          }
        }),
        _vm._v(" "),
        _c("span", { staticClass: "mx-2" }, [_vm._v("No")])
      ]),
      _vm._v(" "),
      _c("label", {}, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.shared,
              expression: "shared"
            }
          ],
          attrs: { type: "radio", name: "shared", value: "1" },
          domProps: { checked: _vm._q(_vm.shared, "1") },
          on: {
            change: function($event) {
              _vm.shared = "1"
            }
          }
        }),
        _vm._v(" "),
        _c("i", {
          staticClass: "far",
          class: {
            "fa-dot-circle": _vm.shared == 1,
            "fa-circle": _vm.shared == 0
          }
        }),
        _vm._v(" "),
        _c("span", { staticClass: "mx-2" }, [_vm._v("Yes")])
      ])
    ]),
    _vm._v(" "),
    _vm.isShared
      ? _c(
          "div",
          { staticClass: "mt-2 templates__shared__functions" },
          [
            _c("label", { staticClass: "required mb-1" }, [
              _vm._v("Business Areas:")
            ]),
            _vm._v(" "),
            _vm._l(_vm.functions, function(obj) {
              return _c(
                "div",
                {
                  key: obj.business_function_id,
                  on: {
                    click: function($event) {
                      return _vm.toggleSelect(obj.business_function_id)
                    }
                  }
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selected,
                        expression: "selected"
                      }
                    ],
                    attrs: { type: "checkbox", name: "business_functions[]" },
                    domProps: {
                      value: obj.business_function_id,
                      checked: Array.isArray(_vm.selected)
                        ? _vm._i(_vm.selected, obj.business_function_id) > -1
                        : _vm.selected
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.selected,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = obj.business_function_id,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.selected = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.selected = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.selected = $$c
                        }
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("i", {
                    staticClass: "fas mr-1",
                    class: {
                      "fa-check-circle": _vm.isSelected(
                        obj.business_function_id
                      ),
                      "fa-circle": !_vm.isSelected(obj.business_function_id)
                    }
                  }),
                  _vm._v(
                    _vm._s(obj.business_function_text.business_function_text) +
                      "\n        "
                  )
                ]
              )
            }),
            _vm._v(" "),
            _c("label", { staticClass: "required mt-4 mb-2" }, [
              _vm._v("Description:")
            ]),
            _vm._v(" "),
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.desc,
                  expression: "desc"
                }
              ],
              attrs: { name: "description", rows: "5", autofocus: "" },
              domProps: { value: _vm.desc },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.desc = $event.target.value
                }
              }
            })
          ],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "required mb-2" }, [
      _vm._v("Shared Template: "),
      _c("span", [_vm._v("(available to all active users for download)")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }