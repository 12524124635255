<template>    
    <a @click="toggleNav" class="main-nav__toggle">
        <i class="far fa-bars" v-if="!this.active"></i>
        <i class="far fa-times" v-if="this.active"></i>
    </a>
</template>

<script>
  	export default {
    	props: {
		},
		data() {
			return {
                active: false
			}
    	},
		created() {
		},
		methods: {

            toggleNav: function() {
                this.active = !this.active;
                this.$store.dispatch('toggleNav');
            }

		},
		computed: {
		}
  	}
</script>