var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "space-y-1 mb-6" },
    _vm._l(_vm.questions, function(question, index) {
      return _c(
        "template-question",
        {
          key: index,
          attrs: {
            value: question.question_id,
            checked: _vm.isChecked(question.question_id)
          }
        },
        [_vm._v(_vm._s(question.question_text.question_text))]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }