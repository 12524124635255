var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "technical-question" }, [
    _c("form", [
      _c("div", { staticClass: "technical-question__container" }, [
        _c("h3", { staticClass: "technical-question__label" }, [
          _vm._v(_vm._s(_vm.$t("question_label")))
        ]),
        _vm._v(" "),
        !_vm.editing
          ? _c("div", { staticClass: "technical-question__text" }, [
              _vm._v(_vm._s(_vm.question.question))
            ])
          : _c("div", [
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.question.question,
                    expression: "question.question"
                  }
                ],
                staticClass: "technical-question__textarea",
                attrs: {
                  id: "question-" + _vm.question.technical_question_id,
                  name: "question"
                },
                domProps: { value: _vm.question.question },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.question, "question", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass: "technical-questions__remaining",
                  class: {
                    "technical-questions__remaining--error": _vm.errorQuestion
                  }
                },
                [
                  _vm._v(
                    "\n\t\t\t\t" +
                      _vm._s(_vm.$t("characters_left")) +
                      ": " +
                      _vm._s(_vm.charactersRemainingQuestion) +
                      "\n\t\t\t"
                  )
                ]
              )
            ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "technical-question__container" }, [
        _c("h3", { staticClass: "technical-question__label" }, [
          _vm._v(_vm._s(_vm.$t("positive_indicators")))
        ]),
        _vm._v(" "),
        !_vm.editing
          ? _c("div", { staticClass: "technical-question__text" }, [
              _vm._v(_vm._s(_vm.question.strong_answer))
            ])
          : _c("div", [
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.question.strong_answer,
                    expression: "question.strong_answer"
                  }
                ],
                staticClass: "technical-question__textarea",
                attrs: {
                  id: "strong-answer-" + _vm.question.technical_question_id,
                  name: "strong-answer"
                },
                domProps: { value: _vm.question.strong_answer },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.question, "strong_answer", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _c("p", {
                staticClass: "technical-questions__remaining",
                class: {
                  "technical-questions__remaining--error": _vm.errorStrongAnswer
                }
              })
            ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "technical-question__container" }, [
        _c("h3", { staticClass: "technical-question__label" }, [
          _vm._v(_vm._s(_vm.$t("negative_indicators")))
        ]),
        _vm._v(" "),
        !_vm.editing
          ? _c("div", { staticClass: "technical-question__text" }, [
              _vm._v(_vm._s(_vm.question.weak_answer))
            ])
          : _c("div", [
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.question.weak_answer,
                    expression: "question.weak_answer"
                  }
                ],
                staticClass: "technical-question__textarea",
                attrs: {
                  id: "weak-answer-" + _vm.question.technical_question_id,
                  name: "weak-answer"
                },
                domProps: { value: _vm.question.weak_answer },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.question, "weak_answer", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass: "technical-questions__remaining",
                  class: {
                    "technical-questions__remaining--error": _vm.errorWeakAnswer
                  }
                },
                [
                  _vm._v(
                    "\n\t\t\t\tCharacters left: " +
                      _vm._s(_vm.charactersRemainingWeakAnswer) +
                      "\n\t\t\t"
                  )
                ]
              )
            ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { class: _vm.localeClass }, [
      !_vm.editing
        ? _c(
            "a",
            { staticClass: "btn btn--primary", on: { click: _vm.toggleEdit } },
            [_vm._v(_vm._s(_vm.$t("buttons.lbl_edit")))]
          )
        : _c(
            "a",
            { staticClass: "btn btn--primary", on: { click: _vm.saveEdit } },
            [_vm._v(_vm._s(_vm.$t("buttons.lbl_save")))]
          ),
      _vm._v(" "),
      _c(
        "a",
        { staticClass: "btn btn--hollow", on: { click: _vm.deleteQuestion } },
        [_vm._v(_vm._s(_vm.$t("buttons.lbl_delete")))]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }