var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "star-rating" },
    _vm._l(_vm.values, function(value, index) {
      return _c(
        "span",
        { key: index, class: [{ checked: _vm.isChecked(value) }] },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.selected,
                expression: "selected"
              }
            ],
            attrs: { id: "star-" + value, type: "radio", name: "rating" },
            domProps: { value: value, checked: _vm._q(_vm.selected, value) },
            on: {
              change: [
                function($event) {
                  _vm.selected = value
                },
                _vm.onChange
              ]
            }
          }),
          _vm._v(" "),
          _c("label", { attrs: { for: "star-" + value } }, [
            _c("i", { staticClass: "fa fa-star" })
          ])
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }