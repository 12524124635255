<template>
	<div class="">
		<div @click="toggleContent" class="accordion__header"><i :class="{ 'fa-chevron-up' : active, 'fa-chevron-down': !active }" class="far mr-2"></i>{{ group.competency_group_text.competency_group_title }}</div>
		<div class="accordion__content" :style="{ 'max-height' : contentHeight }" >
			<div class="accordion__grid" ref="group_content">
				<competency-item @activate="onActivate" v-for="competency in group.competencies" :key="competency.competency_id" :competency="competency" :value="competency.competency_id" :checked="isChecked(competency)"><span class="citem--required" v-if="competency.competency_mandatory">*</span>{{ competency.competency_text.competency_title }}</competency-item>
			</div>
		</div>
	</div>
</template>

<script>

import { eventBus } from '../store/store.js';

export default {
	props: {
		group: Object,
		selected: {
			type: Array,
			default: []
		},
	}
	, data() {
		return {
			ids: []
			, active: false
			, content_height: 0
		}
	}
	, computed: {
		contentHeight() {
			return this.active ? this.content_height+'px' : 0;
		},

		hasOldData: function() {
            return this.$store.getters.getData.hasOwnProperty('old');
        },

        old: function() {
            return this.$store.getters.getData['old'];
        },
	}
	, created() {

		this.ids = this.group.competencies.map((comp) => {
			return comp.competency_id;
		});
		
		eventBus.$on('bandchange', (v) => {
			if (this.active) this.toggleContent();
		});

	}
	, watch: {

		selected: function() {				

			if (!this.hasOldData && this.selected.some(id => this.ids.includes(id))) {
				
				this.toggleContent();
			}

		}
		
	}
	, mounted() {

		this.$nextTick(() => {

		let vm = this;
			window.addEventListener('resize', _.debounce(function(ev) { 
				vm.setContentHeight()
			}, 100));
		});
		
	}
	, methods: {

		onActivate: function() {
			if (!this.active) this.toggleContent();
		}
		,toggleContent() {
			this.active = !this.active;
			this.setContentHeight();
		}
		, setContentHeight() {
			this.$nextTick(() => {
				this.content_height = this.active ? this.$refs.group_content.scrollHeight : 0;
			});
		}
		, isChecked(v) {
			return _.indexOf(this.selected, v.competency_id) > -1 ? true : false;
		}
	}
}
</script>