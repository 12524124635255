<template>
    <div>
        <h4 class="" v-if="hasResults && user">{{ title }}</h4>
         
         <div :class="{ 'my-6' : user}" v-if="hasResults && !user">
            <div class="feedback feedback__rating mb-4">
                <div class="mr-2 feedback__title">Average user rating: <strong>{{ meta.feedback.average_rating }}</strong>
                    <!-- <i class="star fa fa-star" v-for="(n, index) in avg" :key="index"></i> -->
                    <span class="">(rated by {{ meta.feedback.total_users }} user)</span>
                </div>
            </div>
         </div>
         
        <h4 v-if="!hasResults && !user">There is no available feedback for review.</h4>
        
        <div class="feedback mb-4" v-for="(result, index) in results" :key="index">		
            <i class="fa fa-star star" v-for="n in result.rating" :key="n"></i>
            <p class="feedback__excerpt mt-2 mb-1">{{ getExceprt(result.feedback) }}</p>
            <p class="feedback__details">{{ $t('submitted_by')+' '+result.user+' on '+result.created_at }}</p>
            <div class="mt-2 feedback__options">
                <a :href="'/feedback/'+result.id"><i class="fas fa-eye mr-1"></i> View</a>
                <a @click="onDelete(result)"><i class="fas fa-trash-alt mr-1"></i> Delete</a>
            </div>
        </div>
        <paginated-results :current="meta.current_page" :last="meta.last_page" :total="meta.total" :prev="links.prev" :next="links.next" :path="url" @paginate="onPaginate"></paginated-results>
        <modal-dialog title="Delete Feedback?" :open="isOpen" @cancel="onCancel" @close="onClose" @confirm="onConfirm" okLbl="Yes" cancelLbl="No">Are you sure you want to delete this feedback?</modal-dialog>
    </div>
</template>

<script>

export default { 
	props: {
        title: String,
        user: Number,
        reload: {
            type: Boolean,
            default: true
        }
	},
	data() {
		return {
            url: '/api/feedback',
            search: '',
            results: [],
            meta: [],
            links: [],
            page: 1,
            isOpen: false,
            feedbackid: null,
            confirmed: false,
            avg: 0
		}
	}
	, created() {

        if (this.user)
        {
            this.url = '/api/users/'+this.user+'/feedback';
        }

        this.fetchFeedback();   
	}
	, methods: {

        fetchFeedback: function() {
            
            axios.get(this.url, {
                params: {
					page: this.page
				}
            }).then(response => {
                this.results = response.data.data
                this.meta = response.data.meta
                this.links = response.data.links
                this.avg = _.round(this.meta.feedback.average_rating)
            });
        },

        getExceprt: function(s) {
            return (!_.isEmpty(s) && s.length > 100) ? _.trim(s.substring(0,100))+"..." : s;
        },

        onDelete: function(d) {
            this.feedbackid = d.id;
            this.isOpen = true;
        },

        onCancel: function() {
            this.isOpen = false;
        },

        onClose: function() {
            this.feedbackid = null;
            this.confirmed = false;
        },

        onConfirm: function() {

            axios.delete('/api/feedback/'+this.feedbackid+'/delete').then(response => {
                this.confirmed = true;
                this.feedbackid = null;
                this.fetchFeedback();
                this.isOpen = false;
            })
            
        },
        
        onReset: function() {
            this.feedbackid = null;
            this.confirmed = false;
            this.fetchFeedback();
        },

        onPaginate: function(p) {
            this.page = p;
            this.fetchFeedback();
        },

        onFilter: function(p) {
            this.fetchFeedback();
        }
        
	},
    computed: {
        noResults: function() {
            return !this.results.length;
        },
        hasResults: function() {
            return this.results.length;
        },
        getPaginatedURI: function() {
            return this.url+'?page='+this.page;
        },
        getExportURI: function() {
            return '/admin/feedback/export';
        }

    }
}
</script>