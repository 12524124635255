var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    { staticClass: "main-nav__toggle", on: { click: _vm.toggleNav } },
    [
      !this.active ? _c("i", { staticClass: "far fa-bars" }) : _vm._e(),
      _vm._v(" "),
      this.active ? _c("i", { staticClass: "far fa-times" }) : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }