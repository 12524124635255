var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "select",
    {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.band_id,
          expression: "band_id"
        }
      ],
      attrs: { name: "band_id" },
      on: {
        change: function($event) {
          var $$selectedVal = Array.prototype.filter
            .call($event.target.options, function(o) {
              return o.selected
            })
            .map(function(o) {
              var val = "_value" in o ? o._value : o.value
              return val
            })
          _vm.band_id = $event.target.multiple
            ? $$selectedVal
            : $$selectedVal[0]
        }
      }
    },
    [
      _c("option", { attrs: { value: "", selected: "selected" } }, [
        _vm._v("Select...")
      ]),
      _vm._v(" "),
      _vm._l(_vm.bands, function(band, index) {
        return _c("option", { key: index, domProps: { value: band.band_id } }, [
          _vm._v(_vm._s(band.band_name))
        ])
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }