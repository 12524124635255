<template>
    <div class="mt-3">
        <div class="" v-if="hasResults">
            <div class="results__row" v-for="(result, index) in results" :key="index">
                <a @click="onDelete(result)" class=""><i class="fas fa-trash-alt mr-2"></i></a>
                <p class="text-gray-600">{{ result.name }}</p>
            </div>
        </div>
        <paginated-results :current="meta.current_page" :last="meta.last_page" :total="meta.total" :prev="links.prev" :next="links.next" :path="url" @paginate="onPaginate"></paginated-results>
        <modal-dialog title="Delete Domain?" :open="isOpen" @cancel="onCancel" @close="onClose" @confirm="onConfirm" okLbl="Yes" cancelLbl="No">Are you sure you want to delete the domain <strong>{{ domainname }}</strong>?</modal-dialog>
    </div>
</template>

<script>

export default { 
	props: {
	},
	data() {
		return {
            url: '/api/domains',
            search: '',
            results: [],
            meta: [],
            links: [],
            page: 1,
            isOpen: false,
            domainname: '',
            domainid: null,
            confirmed: false
		}
	}
	, created() {

        this.fetchDomains();   
	}
	, methods: {

        fetchDomains: function() {
            
            axios.get(this.getPaginatedURI).then(response => {
                this.results = response.data.data
                this.meta = response.data.meta
                this.links = response.data.links
            });
        },

        onDelete: function(d) {
            this.domainname = d.name;
            this.domainid = d.id;
            this.isOpen = true;
        },

        onCancel: function() {
            this.isOpen = false;
        },

        onClose: function() {
            this.domainname = '';
            this.domainid = null;
            this.confirmed = false;
        },

        onConfirm: function() {

            axios.delete('/api/domains/'+this.domainid+'/delete').then(response => {
                this.confirmed = true;
                this.domainid = null;
                this.fetchDomains();
                this.isOpen = false;
            })
            
        },
        
        onReset: function() {
            this.domainname = '';
            this.domainid = null;
            this.confirmed = false;
            this.fetchDomains();
        },

        onPaginate: function(p) {
            this.page = p;
            this.fetchDomains();
        },

        onFilter: function(p) {
            this.fetchDomains();
        }
        
	},
    computed: {
        noResults: function() {
            return !this.results.length;
        },
        hasResults: function() {
            return this.results.length;
        },
        getPaginatedURI: function() {
            return this.url+'?page='+this.page;
        }

    }
}
</script>