<template>
    <div class="responsive-nav" v-if="isActive">
        <slot></slot>
    </div>
</template>

<script>

    import { eventBus } from '../store/store.js';
    import { mapGetters } from 'vuex';

  	export default {
    	props: {
		},
		data() {
			return {
                isActive: false
			}
    	},
		watch: {

            active: function() {
               this.isActive = this.active;
            }
		},
		methods: {

            onHide: function() {
                this.$store.dispatch('toggleNav');
            }

		},
		computed: {
            
            active: function() {
                return this.$store.getters.navActive;
            },

            ...mapGetters([
                'navActive'
            ])
		}
  	}
</script>