<template>
	<div class="toggle-switch">
		<label class="switch">
			<input type="checkbox" @click="onClick" :checked="active">
			<span class="slider round"></span>
		</label>
		<span class="ml-2">{{ this.active ? 'Active' : 'Deactivated' }}</span>
	</div>
</template>


<script>
  	export default {
    	props: {
			user: {
				type: Number,
				default: null
			},
			status: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				active: false
			}
    	},
		created() {
			this.active = this.status
		},
		methods: {

			onClick: function() {

				if (!this.user) return;
				
				var statusUrl = '/api/users/'+this.user+'/status/'+this.newStatus;

				axios.post(statusUrl).then(response => {
					
					this.active = !this.active;

            	});
			}

		},
		computed: {
			isActive: function() {
				return this.active ? 'bg-green-500' : 'bg-red-500';
			},
			btnX: function() {
				return this.active ? 'translate-x-5' : 'translate-x-0';
			},
			newStatus: function() {
				return this.active ? 3 : 2
			}
		}
  	}
</script>