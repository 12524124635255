let actions = {

    toggleNav({commit}) {
        commit('TOGGLE_NAV')
    },

    setData({commit}, data) {
        commit('SET_DATA', data);
    },
    
}

export default actions