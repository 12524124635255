<template>
     <transition name="fade" v-on:after-leave="onClose">
        <div class="modal-mask" v-if="open">
            <div class="modal-wrapper">
                <div class="modal-container">
                    <div class="modal-header">
						<div class="modal-header--title">
                        	<i class="fas fa-exclamation-circle mr-2"></i>{{ title }}
						</div>
						<a @click="onCancel"><i class="far fa-times"></i></a>
                    </div>
                    <div class="modal-body">
                        <p class=""><slot></slot></p>
                    </div>
                    <div class="modal-footer">
                        <a class="btn btn--primary" @click="onConfirm">{{ okLbl }}</a>
                        <a class="btn btn--hollow" @click="onCancel">{{ cancelLbl }}</a>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    export default {
        props: {
            title: String,
            okLbl: {
                type: String,
                default: 'Ok'
            },
            cancelLbl: {
                type: String,
                default: 'Cancel'
            },
            open: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                // isOpen: true
            }
        },
        methods: {

            onCancel: function() {
                this.$emit('cancel');
            },

            onClose: function() {
                this.$emit('close');
            },

            onConfirm: function() {
                this.$emit('confirm');
            }

        }
    }
</script>