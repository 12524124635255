var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("form", { ref: "searchform" }, [
        _c("div", { staticClass: "search" }, [
          _c("div", { staticClass: "search__filters" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.search,
                  expression: "search"
                }
              ],
              attrs: {
                type: "text",
                placeholder: _vm.$t("templates.search_templates")
              },
              domProps: { value: _vm.search },
              on: {
                input: [
                  function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.search = $event.target.value
                  },
                  _vm.searchTemplates
                ]
              }
            }),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.level,
                    expression: "level"
                  }
                ],
                on: {
                  change: [
                    function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.level = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                    _vm.fetchTemplates
                  ]
                }
              },
              [
                _c("option", { attrs: { value: "" } }, [
                  _vm._v("Select Job Level...")
                ]),
                _vm._v(" "),
                _vm._l(_vm.bands, function(band) {
                  return _c(
                    "option",
                    { key: band.band_id, domProps: { value: band.band_id } },
                    [_vm._v(_vm._s(band.band_text.band_text))]
                  )
                })
              ],
              2
            ),
            _vm._v(" "),
            _vm.shared
              ? _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.bizfunction,
                        expression: "bizfunction"
                      }
                    ],
                    on: {
                      change: [
                        function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.bizfunction = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                        _vm.fetchTemplates
                      ]
                    }
                  },
                  [
                    _c("option", { attrs: { value: "" } }, [
                      _vm._v("Select Business Area...")
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.functions, function(bizfunction) {
                      return _c(
                        "option",
                        {
                          key: bizfunction.business_function_id,
                          domProps: { value: bizfunction.business_function_id }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              bizfunction.business_function_text
                                .business_function_text
                            )
                          )
                        ]
                      )
                    })
                  ],
                  2
                )
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "search__controls mt-4" }, [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "a",
              { staticClass: "btn btn--hollow", on: { click: _vm.onReset } },
              [_c("i", { staticClass: "fas fa-undo mr-3" }), _vm._v("Reset")]
            )
          ])
        ])
      ]),
      _vm._v(" "),
      !_vm.shared
        ? _c("div", {
            staticClass: "search__title mb-4",
            domProps: {
              innerHTML: _vm._s(
                _vm.$tc(
                  "templates.num_templates",
                  _vm.meta.total,
                  _vm.meta.total
                )
              )
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.shared
        ? _c("div", {
            staticClass: "search__title mb-4",
            domProps: {
              innerHTML: _vm._s(
                _vm.$tc(
                  "templates.num_shared_templates",
                  _vm.meta.total,
                  _vm.meta.total
                )
              )
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.results, function(result, index) {
        return _c("div", { key: index, staticClass: "results py-3" }, [
          _c("div", { staticClass: "results__excerpt mb-2" }, [
            _vm._v(_vm._s(result.name))
          ]),
          _vm._v(" "),
          _vm.shared
            ? _c(
                "div",
                { staticClass: "results__functions my-3" },
                _vm._l(result.business_functions, function(biz, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "results__functions--function mr-1"
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(biz.business_function_name) +
                          "\n            "
                      )
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.shared
            ? _c("div", { staticClass: "results__details mb-2" }, [
                _vm._v("Status: "),
                _c("strong", [
                  _c(
                    "span",
                    {
                      class: {
                        "status-complete": result.complete == 100,
                        "status-incomplete": result.complete < 100
                      }
                    },
                    [_vm._v(_vm._s(result.complete) + "%")]
                  )
                ]),
                _vm._v(" complete, last Updated: " + _vm._s(result.updated))
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.shared
            ? _c("div", { staticClass: "results__details mb-2" }, [
                _vm._v("Last Updated: " + _vm._s(result.updated))
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "results__options" }, [
            result.complete == 100
              ? _c(
                  "a",
                  { attrs: { href: "/templates/" + result.id + "/download" } },
                  [
                    _c("i", { staticClass: "fas fa-file-download mr-2" }),
                    _vm._v("Download")
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.shared
              ? _c(
                  "a",
                  { attrs: { href: "/templates/" + result.id + "/edit" } },
                  [
                    _c("i", { staticClass: "fas fa-pencil mr-2" }),
                    _vm._v("Edit")
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.shared
              ? _c(
                  "a",
                  {
                    on: {
                      click: function($event) {
                        return _vm.onDuplicate(result)
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "fas fa-copy mr-2" }),
                    _vm._v("Duplicate")
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.shared
              ? _c(
                  "a",
                  {
                    on: {
                      click: function($event) {
                        return _vm.onDelete(result)
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "fas fa-trash-alt mr-2" }),
                    _vm._v("Delete")
                  ]
                )
              : _vm._e()
          ])
        ])
      }),
      _vm._v(" "),
      _c("paginated-results", {
        attrs: {
          current: _vm.meta.current_page,
          last: _vm.meta.last_page,
          total: _vm.meta.total,
          prev: _vm.links.prev,
          next: _vm.links.next,
          path: _vm.url
        },
        on: { paginate: _vm.onPaginate }
      }),
      _vm._v(" "),
      _c(
        "modal-dialog",
        {
          attrs: {
            title: "Delete Template?",
            open: _vm.isOpen,
            okLbl: "Yes",
            cancelLbl: "No"
          },
          on: {
            cancel: _vm.onCancel,
            close: _vm.onClose,
            confirm: _vm.onConfirm
          }
        },
        [
          _vm._v("Are you sure you want to delete the template "),
          _c("strong", [_vm._v(_vm._s(_vm.templatename))]),
          _vm._v("?")
        ]
      ),
      _vm._v(" "),
      _c(
        "modal-dialog",
        {
          attrs: {
            title: "Duplicate Template?",
            open: _vm.isDuplicate,
            okLbl: "Yes",
            cancelLbl: "No"
          },
          on: {
            cancel: _vm.onCancel,
            close: _vm.onClose,
            confirm: _vm.onConfirm
          }
        },
        [
          _vm._v("Are you sure you want to duplicate the template "),
          _c("strong", [_vm._v(_vm._s(_vm.templatename))]),
          _vm._v("?")
        ]
      )
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "btn btn--primary",
        staticStyle: { flex: "1" },
        attrs: { href: "/templates/create" }
      },
      [
        _c("i", { staticClass: "far fa-plus mr-3" }),
        _vm._v("Create New Template")
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }