var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "accordion" }, [
    _c(
      "div",
      {
        staticClass: "accordion__header accordion__header--alt",
        on: { click: _vm.toggle }
      },
      [
        _vm._v("\n            " + _vm._s(_vm.title) + "\n            "),
        _c("i", {
          staticClass: "far mr-2",
          class: { "fa-chevron-up": _vm.active, "fa-chevron-down": !_vm.active }
        })
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "accordion__content",
        style: { "max-height": _vm.contentHeight }
      },
      [_c("div", { ref: "content" }, [_vm._t("default")], 2)]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }