<template>	
    <div>
        <form ref="searchform">
            <div class="search">
                <div class="search__filters">
                    <input @input="searchTemplates" v-model="search" type="text" :placeholder="$t('templates.search_templates')" />     
                    <select @change="fetchTemplates" v-model="level">
                        <option value="">Select Job Level...</option>
                        <option v-for="band in bands" :key="band.band_id" :value="band.band_id">{{ band.band_text.band_text }}</option>
                    </select>
                     <select @change="fetchTemplates" v-model="bizfunction" v-if="shared">
                        <option value="">Select Business Area...</option>
                        <option v-for="bizfunction in functions" :key="bizfunction.business_function_id" :value="bizfunction.business_function_id">{{ bizfunction.business_function_text.business_function_text }}</option>
                    </select>
                </div>
                <div class="search__controls mt-4">
                    <a href="/templates/create" class="btn btn--primary" style="flex:1;"><i class="far fa-plus mr-3"></i>Create New Template</a>
                    <a @click="onReset" class="btn btn--hollow"><i class="fas fa-undo mr-3"></i>Reset</a>
                </div>
            </div>
        </form>

        <div class="search__title mb-4" v-html="$tc('templates.num_templates', meta.total, meta.total)" v-if="!shared"></div>
        <div class="search__title mb-4" v-html="$tc('templates.num_shared_templates', meta.total, meta.total)" v-if="shared"></div>
        
        <div class="results py-3" v-for="(result, index) in results" :key="index">
            <div class="results__excerpt mb-2">{{ result.name }}</div>
            <div v-if="shared" class="results__functions my-3">
                <div v-for="(biz, index) in result.business_functions" :key="index" class="results__functions--function mr-1">
                    {{ biz.business_function_name }}
                </div>
            </div>
            <div class="results__details mb-2" v-if="!shared">Status: <strong><span :class="{ 'status-complete' : result.complete == 100, 'status-incomplete' : result.complete < 100 }">{{ result.complete }}%</span></strong> complete, last Updated: {{ result.updated }}</div>
            <div class="results__details mb-2" v-if="shared">Last Updated: {{ result.updated }}</div>
            <div class="results__options">
                <a :href="'/templates/'+result.id+'/download'" v-if="result.complete == 100"><i class="fas fa-file-download mr-2"></i>Download</a>
                <a :href="'/templates/'+result.id+'/edit'" v-if="!shared"><i class="fas fa-pencil mr-2"></i>Edit</a>
                <a @click="onDuplicate(result)" v-if="!shared"><i class="fas fa-copy mr-2"></i>Duplicate</a>
                <a @click="onDelete(result)" v-if="!shared"><i class="fas fa-trash-alt mr-2"></i>Delete</a>
            </div>
        </div>
        <paginated-results :current="meta.current_page" :last="meta.last_page" :total="meta.total" :prev="links.prev" :next="links.next" :path="url" @paginate="onPaginate"></paginated-results>
        <modal-dialog title="Delete Template?" :open="isOpen" @cancel="onCancel" @close="onClose" @confirm="onConfirm" okLbl="Yes" cancelLbl="No">Are you sure you want to delete the template <strong>{{ templatename }}</strong>?</modal-dialog>
        <modal-dialog title="Duplicate Template?" :open="isDuplicate" @cancel="onCancel" @close="onClose" @confirm="onConfirm" okLbl="Yes" cancelLbl="No">Are you sure you want to duplicate the template <strong>{{ templatename }}</strong>?</modal-dialog>
    </div>
</template>

<script>

import { eventBus } from '../store/store.js';

export default {
	props: {
        shared: false,
        bands: {
            type: Array,
            default() {
                return []
            }
        },
        functions: {
            type: Array,
            default() {
                return []
            }
        }
	},
	data() {
		return {
            url: '/api/templates',
            selected: 0,
            results: [],
            meta: [],
            links: [],
            page: 1,
            level: '',
            bizfunction: '',
            isOpen: false,
            isDuplicate: false,
            templatename: '',
            templateid: null,
            search: ''
		}
	}
	, created() {

        this.fetchTemplates();  

        eventBus.$on('refresh', (data) => {
            this.fetchTemplates();
        });
	}
	, methods: {

        searchTemplates: _.debounce(function(ev) {

            this.fetchTemplates();

        }, 500),

        fetchTemplates: function() {
            
            axios.get(this.url, {
                params: {
					page: this.page,
                    search: this.search,
                    shared: this.shared,
                    level: this.level,
                    function: this.bizfunction
				}
            }).then(response => {
                this.results = response.data.data
                this.meta = response.data.meta
                this.links = response.data.links
                
            });
            
        },

        onDelete: function(d) {
            this.templatename = d.name;
            this.templateid = d.id;
            this.isOpen = true;
        },

        onDuplicate: function(d) {
            this.templatename = d.name;
            this.templateid = d.id;
            this.isDuplicate = true;
        },

        onCancel: function() {
            this.isOpen = false;
            this.isDuplicate = false;
        },

        onClose: function() {
            this.templatename = '';
            this.templateid = null;
            this.confirmed = false;
        },

        onConfirm: function() {

            if (this.isDuplicate) {

                axios.post('/api/templates/'+this.templateid+'/duplicate').then(response => {
                    this.confirmed = true;
                    this.templateid = null;
                    this.page = 1;
                    this.fetchTemplates();
                    this.isDuplicate = false;
                    eventBus.$emit('refresh');
                })
            }

            if (this.isOpen) {

                axios.delete('/api/templates/'+this.templateid+'/delete').then(response => {
                    this.confirmed = true;
                    this.templateid = null;
                    this.page = 1;
                    this.fetchTemplates();
                    this.isOpen = false;
                    eventBus.$emit('refresh');
                })
            }
            
        },
        
        onReset: function() {
            this.templatename = '';
            this.confirmed = false;
            this.search = '';
            this.level = '';
            this.bizfunction = '';
            this.fetchTemplates();
        },

        onPaginate: function(p) {
            this.page = p;
            this.fetchTemplates();
        },

        onFilter: function(p) {
            this.fetchTemplates();
        },

        isActive: function(i) {
            return this.selected == i ? 'bg-gray-700 text-white' : 'bg-gray-200 text-gray-500 hover:bg-gray-300 hover:text-gray-500';
        },
        setActive: function(i)
        {
            this.selected = i;
        }
	}
    , computed: {
        hasResults: function() {
            return this.results.length;
        },
        getPaginatedURI: function() {
            //return !_.isEmpty(this.search) ? this.url+'?search='+encodeURI(this.search)+'&shared='+this.shared+'&page='+this.page : this.url+'?shared='+this.isShared+'&page='+this.page;
        },
        isShared: function() {
            return this.shared ? true : false;
        }
    }
}
</script>

