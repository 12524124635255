<template>
	<select name="band_id" class="" v-model="band_id">
        <option value="" selected="selected">Select...</option>
        <option v-for="(band, index) in bands" :key="index" :value="band.band_id">{{ band.band_name }}</option>
	</select>
</template>

<script>

import { eventBus } from '../store/store.js';

export default {
    props: {
        band: {
            type: [String, Number],
            default: null
        }
    },
	data() {
		return {
            band_id: '',
            bands: []
		}
	}
	, created() {

        this.$nextTick(() => {
            if (this.band) this.band_id = this.band;
            this.getBands();
        });
	}
	, methods: {

        getBands: function() {

            axios.get('/api/bands').then(response => {
                this.bands = response.data
            });

        }
        
	}
    , watch: {

        band_id: function () {
            eventBus.$emit('bandchange', this.band_id);
        }
    }
}
</script>

