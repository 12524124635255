<template>
    <div class="mt-2 templates__shared">
        <label class="required mb-2">Shared Template: <span>(available to all active users for download)</span></label>
        <div class="mt-2 templates__shared__controls">
            <label class="">
                <input type="radio" name="shared" value="0" v-model="shared" />
                <i class="far" :class="{ 'fa-dot-circle' :  shared == 0, 'fa-circle' :  shared == 1}"></i>
                <span class="mx-2">No</span>
            </label>
            <label class="">
                <input type="radio" name="shared" value="1" v-model="shared" />
                <i class="far" :class="{ 'fa-dot-circle' :  shared == 1, 'fa-circle' :  shared == 0}"></i>
                <span class="mx-2">Yes</span>
            </label>
        </div>
        <div class="mt-2 templates__shared__functions" v-if="isShared">
            <label class="required mb-1">Business Areas:</label>
            <div v-for="obj in functions" :key="obj.business_function_id" @click="toggleSelect(obj.business_function_id)">
                <input type="checkbox" name="business_functions[]" :value="obj.business_function_id" v-model="selected" />
                <i class="fas mr-1" :class="{ 'fa-check-circle' : isSelected(obj.business_function_id), 'fa-circle' : !isSelected(obj.business_function_id) }"></i>{{ obj.business_function_text.business_function_text }}
            </div>
            
            <label class="required mt-4 mb-2">Description:</label>
            <textarea name="description" class="" rows="5" autofocus v-model="desc"></textarea>
        </div>
    </div>
</template>

<script>
export default {
	props: {
        sharing: {
            type: Number,
            default: 0
        },
        description: {
            type: String,
            default: ''
        },
        functions: {
            type: Array
        },
        old: {
            type: Array,
            default: null
        }
	}
	, data() {
		return {
            shared: 0,
            desc: '',
            selected: []
		}
	}
	, computed: {
        isShared: function() {
            return this.shared == 1;
        }
	}
	, mounted() {
        this.shared = this.sharing
        this.desc = this.description
        if (this.old) {
            
            if (typeof(this.old) === 'object') {
                this.selected = this.old.map(item => {
                    return typeof(item) == 'object' ? item.business_function_id : parseInt(item);
                });
            }
        }
	}   
    , methods: {
        isSelected: function(v) {
            return _.indexOf(this.selected, v) >= 0;
        },
        toggleSelect: function(v) {

            var index = _.indexOf(this.selected, v);

            if (!this.isSelected(v)) {
                this.selected.push(v)
            } else {
                this.selected.splice(index, 1);
            }
        }
    }
    , watch: {

        shared: function () {
            if (!this.isShared) {
                this.desc = '';
                this.selected = [];
            }
        }
    }
}
</script>