import { render, staticRenderFns } from "./ModalDialog.vue?vue&type=template&id=266f36ba&"
import script from "./ModalDialog.vue?vue&type=script&lang=js&"
export * from "./ModalDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/nickmorley/Downloads/AMS-HANDOVER/Royal-London-Interview-Toolkit/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('266f36ba')) {
      api.createRecord('266f36ba', component.options)
    } else {
      api.reload('266f36ba', component.options)
    }
    module.hot.accept("./ModalDialog.vue?vue&type=template&id=266f36ba&", function () {
      api.rerender('266f36ba', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/ModalDialog.vue"
export default component.exports