var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.hasResults && _vm.user
        ? _c("h4", {}, [_vm._v(_vm._s(_vm.title))])
        : _vm._e(),
      _vm._v(" "),
      _vm.hasResults && !_vm.user
        ? _c("div", { class: { "my-6": _vm.user } }, [
            _c("div", { staticClass: "feedback feedback__rating mb-4" }, [
              _c("div", { staticClass: "mr-2 feedback__title" }, [
                _vm._v("Average user rating: "),
                _c("strong", [
                  _vm._v(_vm._s(_vm.meta.feedback.average_rating))
                ]),
                _vm._v(" "),
                _c("span", {}, [
                  _vm._v(
                    "(rated by " +
                      _vm._s(_vm.meta.feedback.total_users) +
                      " user)"
                  )
                ])
              ])
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.hasResults && !_vm.user
        ? _c("h4", [_vm._v("There is no available feedback for review.")])
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.results, function(result, index) {
        return _c(
          "div",
          { key: index, staticClass: "feedback mb-4" },
          [
            _vm._l(result.rating, function(n) {
              return _c("i", { key: n, staticClass: "fa fa-star star" })
            }),
            _vm._v(" "),
            _c("p", { staticClass: "feedback__excerpt mt-2 mb-1" }, [
              _vm._v(_vm._s(_vm.getExceprt(result.feedback)))
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "feedback__details" }, [
              _vm._v(
                _vm._s(
                  _vm.$t("submitted_by") +
                    " " +
                    result.user +
                    " on " +
                    result.created_at
                )
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "mt-2 feedback__options" }, [
              _c("a", { attrs: { href: "/feedback/" + result.id } }, [
                _c("i", { staticClass: "fas fa-eye mr-1" }),
                _vm._v(" View")
              ]),
              _vm._v(" "),
              _c(
                "a",
                {
                  on: {
                    click: function($event) {
                      return _vm.onDelete(result)
                    }
                  }
                },
                [
                  _c("i", { staticClass: "fas fa-trash-alt mr-1" }),
                  _vm._v(" Delete")
                ]
              )
            ])
          ],
          2
        )
      }),
      _vm._v(" "),
      _c("paginated-results", {
        attrs: {
          current: _vm.meta.current_page,
          last: _vm.meta.last_page,
          total: _vm.meta.total,
          prev: _vm.links.prev,
          next: _vm.links.next,
          path: _vm.url
        },
        on: { paginate: _vm.onPaginate }
      }),
      _vm._v(" "),
      _c(
        "modal-dialog",
        {
          attrs: {
            title: "Delete Feedback?",
            open: _vm.isOpen,
            okLbl: "Yes",
            cancelLbl: "No"
          },
          on: {
            cancel: _vm.onCancel,
            close: _vm.onClose,
            confirm: _vm.onConfirm
          }
        },
        [_vm._v("Are you sure you want to delete this feedback?")]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }