<template>
	<div class="accordion">
        <div @click="toggle" class="accordion__header accordion__header--alt">
            {{ title }}
            <i :class="{ 'fa-chevron-up' : active, 'fa-chevron-down': !active }" class="far mr-2"></i>
            
        </div>
        <div class="accordion__content" :style="{ 'max-height' : contentHeight }">
            <div ref="content">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>

import { eventBus } from '../store/store.js';

export default {
    props: {
        title: String
    },
	data() {
		return {
            active: false,
            height: 0
		}
	}
	, created() {
        this.$nextTick(() => {
            let vm = this;
			window.addEventListener('resize', _.debounce(function(ev) { 
				vm.setHeight()
			}, 100));
        });
	}
	, methods: {
        toggle() {
			this.active = !this.active;
			this.setHeight();
		}
		, setHeight() {
			this.$nextTick(() => {
				this.height = this.active ? this.$refs.content.scrollHeight : 0;
			});
		}
	}
    , computed: {
       contentHeight() {
			return this.active ? this.height+'px' : 0;
		},
    }
}
</script>

