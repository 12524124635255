<template>
    <div class="space-y-1 mb-6">
        <template-question v-for="(question, index) in questions" :key="index" :value="question.question_id" :checked="isChecked(question.question_id)">{{ question.question_text.question_text }}</template-question>
    </div>
</template>

<script>

import { eventBus } from '../store/store.js';
import { mapGetters } from 'vuex';

export default {
    props: {
        questions: {
			type: Array,
			default: null
		},
        selected: {
			type: Array,
			default: null
		},
        old: {
			type: Array,
			default: null
		}
    },
	data() {
		return {

		}
	},
    mounted() {
		if (this.old) this.$store.dispatch('setData', {key:'old', value:this.old});
	},
    created() {
    },
    methods: {
        isChecked(v) {
			return _.indexOf(this.selected, v) > -1 ? true : false;
		}
    },
    watch: {
  
    },
    computed: {
    }
}
</script>

