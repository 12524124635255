<template>
	<div>
		<div class="tabs">
			<a @click="selectTab(tab)" v-for="tab in tabs" :key="tab.id" class="btn" :class="{ 'btn--primary': tab.isActive, 'btn--hollow-alt': !tab.isActive }">{{ tab.name }}</a>
		</div>
		<div class="tab-content">
			<slot></slot>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			tabs: []
		}
	}
	, created() {
		this.tabs = this.$children;
	}
	, methods: {
		selectTab(selected_tab) {
			this.tabs.forEach(tab => {
				tab.isActive = (tab.name == selected_tab.name);
			});
		}
	}
}
</script>

