import { render, staticRenderFns } from "./CompetencyItem.vue?vue&type=template&id=ec60188a&"
import script from "./CompetencyItem.vue?vue&type=script&lang=js&"
export * from "./CompetencyItem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/nickmorley/Downloads/AMS-HANDOVER/Royal-London-Interview-Toolkit/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ec60188a')) {
      api.createRecord('ec60188a', component.options)
    } else {
      api.reload('ec60188a', component.options)
    }
    module.hot.accept("./CompetencyItem.vue?vue&type=template&id=ec60188a&", function () {
      api.rerender('ec60188a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/CompetencyItem.vue"
export default component.exports