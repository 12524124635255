<template>
	<div class="star-rating">
		<span v-for="value, index in values" :key="index" :class="[{ checked: isChecked(value)}]">
			<input :id="'star-'+value" type="radio" name="rating" :value="value" @change="onChange" v-model="selected">
			<label :for="'star-'+value">
				<i class="fa fa-star"></i>
			</label>
		</span>
	</div>
</template>

<script>
export default {
	props: {
		rating: Number
	},
	data() {
		return {
			selected: null,
			values: [5,4,3,2,1]
		}
	}
	, created() {
		this.selected = this.rating
	}
	, methods: {
		onChange: function(e) {
			this.selected = e.target.value;
		},
		isChecked: function(i) {
			return i<=this.selected;
		}
	}
}
</script>

