<template>
	<div class="technical-question">
		<form>
		<div class="technical-question__container">
			<h3 class="technical-question__label">{{ $t('question_label') }}</h3>
			<div class="technical-question__text" v-if="!editing">{{ question.question }}</div>
			<div v-else>
				<textarea class="technical-question__textarea" :id="'question-'+question.technical_question_id" name="question" v-model="question.question"></textarea>
				<p class="technical-questions__remaining"
					:class="{ 'technical-questions__remaining--error': errorQuestion }">
					{{ $t('characters_left') }}: {{ charactersRemainingQuestion }}
				</p>
			</div>
		</div>

		<div class="technical-question__container">
			<h3 class="technical-question__label">{{ $t('positive_indicators') }}</h3>
			<div class="technical-question__text" v-if="!editing">{{ question.strong_answer }}</div>
			<div v-else>
				<textarea class="technical-question__textarea" :id="'strong-answer-'+question.technical_question_id" name="strong-answer" v-model="question.strong_answer"></textarea>
				<p class="technical-questions__remaining"
					:class="{ 'technical-questions__remaining--error': errorStrongAnswer }">
				</p>
			</div>
		</div>

		<div class="technical-question__container">
			<h3 class="technical-question__label">{{ $t('negative_indicators') }}</h3>
			<div class="technical-question__text" v-if="!editing">{{ question.weak_answer }}</div>
			<div v-else>
				<textarea class="technical-question__textarea" :id="'weak-answer-'+question.technical_question_id" name="weak-answer" v-model="question.weak_answer"></textarea>
				<p class="technical-questions__remaining"
					:class="{ 'technical-questions__remaining--error': errorWeakAnswer }">
					Characters left: {{ charactersRemainingWeakAnswer }}
				</p>
			</div>
		</div>

		
		</form>

		<div :class="localeClass">
			<a class="btn btn--primary" v-if="!editing" @click="toggleEdit">{{ $t('buttons.lbl_edit') }}</a>
			<a class="btn btn--primary" v-else @click="saveEdit">{{ $t('buttons.lbl_save') }}</a>
			<a class="btn btn--hollow" @click="deleteQuestion">{{ $t('buttons.lbl_delete') }}</a>
		</div>

	</div>
</template>

<script>
export default {
	props: {
		question: Object
		, max_characters: Number
	}
	, data() {
		return {
			editing: false
		}
	}
	, computed: {
		charactersRemainingQuestion() {
			return this.max_characters - this.question.question.length;
		}
		, charactersRemainingWeakAnswer() {
			return this.max_characters - this.question.weak_answer.length;
		}
		, charactersRemainingStrongAnswer() {
			return this.max_characters - this.question.strong_answer.length;
		}
		, canPost() {
			return this.charactersRemainingQuestion >= 0;
				// && this.charactersRemainingWeakAnswer >= 0
				// && this.charactersRemainingStrongAnswer >= 0;
		}
		, errorQuestion() {
			return this.charactersRemainingQuestion < 0;
		}
		, errorWeakAnswer() {
			return this.charactersRemainingWeakAnswer < 0;
		}
		, errorStrongAnswer() {
			return this.charactersRemainingStrongAnswer < 0;
		},
		localeClass: function() {
			return 'technical-question__controls technical-question__controls--'+document.getElementsByTagName('html')[0].getAttribute('lang');
		},
	}
	, methods: {
		saveEdit() {
			axios.post('/api/templates/'+this.question.template_id+'/custom/'+this.question.technical_question_id+'/edit', {
				technical_question_id: this.question.technical_question_id
				, question: this.question.question
				, weak_answer: this.question.weak_answer
				, strong_answer: this.question.strong_answer
			}).then((response) => {
				this.toggleEdit();
			});
		}
		, toggleEdit() {
			this.editing = !this.editing
		}
		, deleteQuestion() {

			this.$emit('delete', this.question);

			// axios.post('/api/templates/'+this.question.template_id+'/custom/'+this.question.technical_question_id+'/delete', {
			// 	technical_question_id: this.question.technical_question_id
			// }).then((response) => {
			// 	this.$emit('technical-question-remove', this.question.technical_question_id);
			// });
		}
	}
}
</script>