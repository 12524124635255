var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt-3" },
    [
      _vm.hasResults
        ? _c(
            "div",
            {},
            _vm._l(_vm.results, function(result, index) {
              return _c("div", { key: index, staticClass: "results__row" }, [
                _c(
                  "a",
                  {
                    on: {
                      click: function($event) {
                        return _vm.onDelete(result)
                      }
                    }
                  },
                  [_c("i", { staticClass: "fas fa-trash-alt mr-2" })]
                ),
                _vm._v(" "),
                _c("p", { staticClass: "text-gray-600" }, [
                  _vm._v(_vm._s(result.name))
                ])
              ])
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _c("paginated-results", {
        attrs: {
          current: _vm.meta.current_page,
          last: _vm.meta.last_page,
          total: _vm.meta.total,
          prev: _vm.links.prev,
          next: _vm.links.next,
          path: _vm.url
        },
        on: { paginate: _vm.onPaginate }
      }),
      _vm._v(" "),
      _c(
        "modal-dialog",
        {
          attrs: {
            title: "Delete Domain?",
            open: _vm.isOpen,
            okLbl: "Yes",
            cancelLbl: "No"
          },
          on: {
            cancel: _vm.onCancel,
            close: _vm.onClose,
            confirm: _vm.onConfirm
          }
        },
        [
          _vm._v("Are you sure you want to delete the domain "),
          _c("strong", [_vm._v(_vm._s(_vm.domainname))]),
          _vm._v("?")
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }