import Vue from 'vue'

let mutations = {

    TOGGLE_NAV(state) {
        state.nav = !state.nav
    },

    SET_DATA(state, obj) {
        Vue.set(state.data, obj.key, obj.value);
    }
    
}

export default mutations